import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/en/Layout"
import HomeSection from "../../components/en/HomeSection"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

const CollectionTemplate = ({ data }) => {
  const collection = data.collectionsEn

  const metaDescription =
    collection.description ||
    `Access the best content about spirituality, history and islam wisdom
    with our collections`

  const renderDescription = () => {
    if (collection.description) {
      return <p>{collection.description}</p>
    }

    return (
      <p>
        Access the best content about spirituality, history and islam wisdom
        with our collections
      </p>
    )
  }

  return (
    <Layout>
      <SEO
        title={collection.name}
        description={metaDescription}
        card={false}
        image={collection.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/en/collections/${collection.slug}`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage={collection.name}
            secondaryLink={"/en/collections"}
            secondaryLinkName="Collections"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>{collection.name}</h1>
            {renderDescription()}
          </div>

          <HomeSection
            name=""
            cards={collection.cards}
            style={{ marginTop: 50, marginBottom: 50 }}
            isCollection={true}
            customClass="home-section-card-list"
          />
        </div>
      </div>
    </Layout>
  )
}

export default CollectionTemplate

export const query = graphql`
  query($id: String!) {
    collectionsEn(id: { eq: $id }) {
      name
      slug
      description
      createdAt
      imageUrl
      cards {
        name
        slug
        imageUrl
        type
        topic {
          name
          color
        }
        tags {
          name
        }
        meta {
          content
          author
        }
      }
    }
  }
`
